import React, { useState } from 'react';
import { useGetContract } from './useGetContract';
import { DEFAULT_GAS_LIMIT, ContractNames } from '../constants';
import { current } from '@reduxjs/toolkit';
import { toWei } from '../numbers';
import { realNumberToTransactionalNumber } from '../format';
import { increaseByPercent } from '../numbers';

export const useUpgradeLvl = () => {
  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);
  const { getContract } = useGetContract();

  const upgradeLvl = async (matrix = 'base', level = 2, count = 1, account, currency, value) => {
    if (!isLoadingUpgrade) {
      setIsLoadingUpgrade(true);
      const buyParam = new Array(count).fill(level);

      try {
        if (matrix === 'base') {
          const contract = await getContract(ContractNames.MATRIX_B);

          let gas = null;
          try {
            gas = await contract.estimateGas.buyMultipleLevels(account, buyParam);
          } catch (e) {
            //
          }

          const result = await contract.buyMultipleLevels(account, buyParam, {
            gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
          });
          return result;
        } else {
          let gas = null;
          let result = null;

          if (currency === 'usdt') {
            const contract = await getContract(ContractNames.PAYMENT_ROUTER);

            try {
              gas = await contract.estimateGas.qornex2BuyLevelBatch(
                '0x55d398326f99059fF775485246999027B3197955',
                account,
                [level],
                0,
              );
            } catch (e) {
              //
            }

            result = await contract.qornex2BuyLevelBatch(
              '0x55d398326f99059fF775485246999027B3197955',
              account,
              [level],
              0,
              {
                gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
              },
            );

            return result;
          } else {
            const contract = await getContract(ContractNames.PAYMENT_ROUTER);
            const bigNumberValue = realNumberToTransactionalNumber(value);
            const currencyAddress =
              currency === 'bnb'
                ? '0x0000000000000000000000000000000000000000'
                : '0xC703dA39aE3B9Db67C207c7Bad8100E1AfdC0F9c';

            if (currency === 'bnb') {
              try {
                gas = await contract.estimateGas.qornex2BuyLevelBatch(currencyAddress, account, [level], 0, {
                  value: toWei(value),
                });
              } catch (e) {}

              result = await contract.qornex2BuyLevelBatch(currencyAddress, account, [level], 0, {
                value: toWei(value),
                gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
              });
            } else {
              try {
                gas = await contract.estimateGas.qornex2BuyLevelBatch(
                  currencyAddress,
                  account,
                  [level],
                  bigNumberValue,
                );
              } catch (e) {
                //
              }

              result = await contract.qornex2BuyLevelBatch(currencyAddress, account, [level], bigNumberValue, {
                gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
              });
            }

            return result;
          }
        }
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      } finally {
        setIsLoadingUpgrade(false);
      }
    }
  };

  return {
    upgradeLvl,
    isLoadingUpgrade,
  };
};
